import { useState } from "react"
import styled from "styled-components"

import { saveAuth } from "src/components/Flags/flagsAuthUtil"
import { MButton } from "src/ui/Button/MButton"
import { MTextField } from "src/ui/MTextField/MTextField"
import { spacing } from "src/ui/spacing"

export function FlagsAuth({
  onSaveAuth,
  reminder,
}: {
  onSaveAuth: () => void
  reminder: string
}) {
  const [text, setText] = useState("")
  async function handleSaveAuth() {
    if (text !== reminder) {
      return
    }
    saveAuth()
    onSaveAuth()
  }
  return (
    <BoxWrap>
      <Box>
        <MTextField
          label="The first rule is"
          value={text}
          onChange={(value) => setText(value)}
        />
        <MButton onClick={handleSaveAuth} disabled={text !== reminder}>
          Acknowledge
        </MButton>
      </Box>
    </BoxWrap>
  )
}

const BoxWrap = styled.div`
  padding: ${spacing.M};
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Box = styled.div`
  display: grid;
  gap: ${spacing.M};
  max-width: 65ch;
  width: 100%;
`
