import { debug } from "src/utils/logger"
import { localStorageFactory } from "src/utils/storageUtil"

const DAYS_VALID = 30
const DAY_IN_MS = 24 * 60 * 60 * 1000

export function checkFlagsAuth() {
  const storedAuth = flagAuthStorage.get()
  if (!storedAuth) return false

  const lastAutenticated = new Date(storedAuth.authDate).getTime()
  const expireDate = new Date(
    lastAutenticated + DAYS_VALID * DAY_IN_MS
  ).getTime()
  const authExpired = Date.now() > expireDate
  if (authExpired) {
    debug.log("Auth expired, revalidate")
    flagAuthStorage.clear()
    return false
  }

  return storedAuth.hasAuth
}

export function saveAuth() {
  flagAuthStorage.set({ authDate: new Date().toISOString(), hasAuth: true })
}

interface IFlagAuthStorage {
  hasAuth: boolean
  authDate: string // iso string
}
export const flagAuthStorage = localStorageFactory<IFlagAuthStorage>({
  key: "minut.flagauth",
})
